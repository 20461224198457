/******* screen-small.less *******/
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
/* libre-baskerville-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'LibreBaskervilleBold';
  src: url('/extras/fonts/libre-baskerville-v14-latin-700.woff2') format('woff2');
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/******* reset.less 2013-1-16 *******/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
pre,
abbr,
code,
em,
hr,
img,
strong,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
input,
textarea,
button,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
audio,
video {
  margin: 0;
  padding: 0;
  outline: 0;
  border: 0 #fff;
  /* color for input.file */
  background: transparent;
  vertical-align: baseline;
  font-size: 100%;
}
ul {
  list-style: none;
}
a:active,
a:focus {
  outline: none;
}
address {
  font-style: normal;
}
img {
  display: block;
  /* risky, was #root div.show img, cut at #disp .body img, */
  border: none;
  -ms-interpolation-mode: bicubic;
}
/******* cmsbox.less 2014-08-26 *******/
#desk,
.unit,
.part {
  position: relative;
}
.stop,
.unit > .foot {
  clear: both;
}
#head,
.area,
.unit,
.unit > .body,
.unit > form > .body,
.part {
  float: left;
}
.unit {
  margin-top: 15px;
  margin-bottom: 15px;
}
.unit > .body,
.unit > form > .body {
  width: 100%;
}
.fold > .body {
  min-height: 0;
}
.part,
.grid table {
  margin-top: 15px;
  margin-bottom: 15px;
}
.lead {
  margin-bottom: 0 !important;
}
#edit .line {
  padding: 4px 0;
}
.line > hr {
  overflow: hidden;
  height: 1px;
  border-color: #000;
  color: #000;
  font-size: 0;
  line-height: 0;
}
.hair {
  border-bottom-style: solid;
}
.dash {
  border-bottom-style: dashed;
}
.spot {
  border-bottom-style: dotted;
}
.fine {
  border-bottom-width: 1px;
}
.bold {
  border-bottom-width: 2px;
}
.link > .open,
.load > .load {
  display: block;
}
a.load {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -moz-text-overflow: ellipsis;
  -webkit-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
a.link {
  background: none;
}
.null,
.null:hover,
.null:focus {
  color: #000 !important;
  text-decoration: none !important;
  cursor: default;
}
.pict > img,
.pict > a > img,
.file > img,
.file > a > img,
.film > img,
.film > a > img,
.play > img,
.cb-googlemapscontainer,
.cb-zoom-image {
  width: 100%;
  height: auto;
}
a.zoom {
  position: relative;
  display: block;
}
img.zoom {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -18px 0 0 -17px !important;
  width: 35px !important;
  height: 35px;
}
.cb-zoom-image {
  overflow: hidden;
  margin: 0 auto;
  color: #ccc;
  font-size: 12px;
  font-family: monaco, 'courier new', courier, monospace;
  line-height: 1.2;
}
.crop {
  position: relative;
  overflow: hidden;
  height: 320px;
}
.crop > img {
  position: absolute;
  width: auto;
}
.plug {
  overflow: hidden;
  background-position: 50%;
  background-repeat: no-repeat;
}
p.plug {
  max-width: 100%;
  background-size: contain;
}
.flash,
.flash embed,
.flash object,
.crossslide {
  overflow: hidden;
  width: 100%;
}
div.flash > div {
  width: 100%;
  height: 100%;
}
.cb-youtube > .flash,
.cb-youtube > .null {
  background: url('/icons/youtube_logo.png') no-repeat 50%;
}
.cb-vimeo > .flash,
.cb-vimeo > .null {
  background: url('/icons/vimeo_logo.png') no-repeat 50%;
}
.cb-youtube > .cb-youtube,
.cb-vimeo > .cb-vimeo {
  overflow: hidden;
  background-size: contain;
  background-repeat: no-repeat;
}
.cb-vimeo iframe,
.cb-youtube iframe {
  width: 100%;
  height: 100%;
  display: block;
  border: none;
  /* iframe borderframe */
}
.cb-statistics .cb-loading {
  margin: 10px auto 25px;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: #111 url(/icons/load.gif) no-repeat 50%;
}
.cb-service > a {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  margin-top: 2px;
  margin-right: 10px;
  padding-bottom: 4px;
  width: 16px;
  height: 16px;
  background: url('/icons/email.png') no-repeat;
}
.cb-service > a:hover {
  background-position: 0px -20px;
}
.cb-service > a:active {
  background-position: 0px -40px;
}
.cb-service > .cb-delicious {
  background-image: url('/icons/delicious.png');
}
.cb-service > .cb-digg {
  background-image: url('/icons/digg.png');
}
.cb-service > .cb-twitter {
  background-image: url('/icons/twitter.png');
}
.cb-service > .cb-facebook {
  background-image: url('/icons/facebook.png');
}
.cb-service > .cb-service {
  position: relative;
  float: left;
  margin-bottom: 8px;
  padding: 2px 5px 2px 0;
  height: 24px;
  background: red;
}
.code pre {
  font-family: monaco, 'courier new', courier, monospace;
}
a.plug {
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  display: block;
  width: 100%;
  height: 100%;
  background: url('/objects/flashplayer/images/adobe.png') no-repeat 50%;
  background-color: rgba(255, 255, 255, 0.5);
}
img.cb-loading {
  overflow: hidden;
  background: #111 url(/icons/load.gif) no-repeat 50%;
  opacity: 0.35;
}
.hidden,
.fake,
.read {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
}
.cb-invisible {
  display: none !important;
}
.cb-access {
  display: inline;
  float: left;
  overflow: hidden;
  width: 0;
  height: 0;
}
caption {
  display: none;
}
#over {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3000;
  /* .mark in edit mode z-index: 200 */
  overflow: hidden;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
}
#disp {
  position: absolute;
  z-index: 3001;
  color: #000;
  font-family: 'Montserrat', helvetica, sans-serif;
  line-height: 1.6;
  font-size: 15px;
}
#disp .head,
#disp h2,
#disp .head a.quit {
  color: #fff;
  line-height: 24px;
}
#disp .head {
  position: relative;
  overflow: hidden;
}
#disp h2 {
  font-size: 21px;
  font-size: 2.1rem;
  margin: 0 10px;
  padding-right: 30px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
  letter-spacing: 0.025em;
  font-weight: 300;
}
#disp.zoom h2 {
  padding-right: 80px;
}
#disp .head .ctrl {
  position: absolute;
  top: 2px;
  right: 8px;
  width: 74px;
}
#disp .head .cb-hybrid,
#disp a.quit {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  float: left;
  width: 22px;
  height: 24px;
  background: url('/icon-lightbox/lb-ctr.png') no-repeat;
  font-size: 0;
}
#disp .head .cb-hybrid:hover,
#disp a.quit:hover,
#disp .head .cb-hybrid:focus,
#disp a.quit:focus {
  opacity: 0.75;
}
#disp .head .cb-hybrid:active,
#disp a.quit:active {
  opacity: 0.5;
}
#disp .prev > .cb-hybrid {
  background-position: 3px 50%;
}
#disp .next > .cb-hybrid {
  background-position: -15px 50%;
}
#disp a.quit {
  float: right;
  background-position: -50px 50%;
}
/* DISP BODY */
#disp .body {
  overflow: hidden;
  margin: 10px 10px 0;
  padding: 15px;
  border-radius: 2px 2px 0 0;
  background: #fff;
}
#disp.mail .body,
#disp.resp .body {
  padding-bottom: 0;
}
#disp.mail .foot,
#disp.resp .foot,
#disp.auth .foot {
  padding-bottom: 10px;
  text-align: right;
}
.mail p + fieldset + fieldset {
  min-height: 2.8em;
  /* no jumping after recipient address renders */
}
#disp a {
  color: #404040;
}
.cb-disp-sent,
.cb-disp-sent:hover {
  display: block;
  padding: 0.4em 14px;
  text-align: left;
  text-decoration: none;
}
#disp fieldset,
#disp .part.recaptcha-info {
  padding: 0.2em 0 0.5em;
}
#disp .part.recaptcha-info {
  width: 100%;
  margin: 0;
}
#disp .mail,
#disp select,
#disp textarea,
#disp .tick,
#disp .text,
#disp .file,
#disp .password {
  display: block;
  width: 100%;
}
#disp textarea {
  height: 160px;
}
#disp label.name {
  font-weight: bold;
  display: block;
  padding-bottom: 0.2em;
  width: 100%;
}
#disp label.sign {
  float: right;
  /* used where ? */
  width: 278px;
}
#disp div.tack {
  float: right;
  width: 20px;
}
/* DISP FOOT */
#disp .foot {
  overflow: hidden;
  margin: 0 10px;
  padding: 0 15px 0.5em;
  border-radius: 0 0 2px 2px;
  background: #fff;
  text-align: left;
}
#disp .foot p.prev,
#disp .foot p.next {
  float: left;
  /* used where ? */
  width: 40%;
}
#disp .foot p.next {
  /* used where ? */
  float: right;
  text-align: right;
}
#disp .foot input.prev,
#disp .canc,
#disp .foot .button {
  margin-left: 15px;
}
#disp .submit,
#disp .foot input.next,
#disp .conf,
#disp .foot .button,
#disp.message .submit {
  float: right;
}
a.capt {
  float: left;
  margin-bottom: 0.5em;
  padding: 0.3em 0;
  background: #000;
}
#disp a.capt {
  width: 100%;
}
img.capt {
  margin: 0 auto;
}
#disp.zoom .foot,
#disp.zoom .foot > fieldset {
  padding-bottom: 0;
}
.zoom fieldset > p {
  padding: 0 15px 25px;
  color: #404040;
}
#disp > .body > .part {
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
}
#disp.film p.plug {
  width: 100%;
  height: 100%;
}
#disp .part > .flash {
  max-width: 100%;
}
/* CODE */
#disp.cb-lightbox-code textarea {
  height: 400px;
  font-family: monaco, 'courier new', courier, monospace;
}
/* DISP SITEMAP */
.cb-sitemap-target {
  font-size: 12px;
  font-size: 1.2rem;
  overflow: hidden;
  padding-bottom: 1em;
  color: #e64246;
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  line-height: 1.2;
}
#disp.site li,
.cb-sitemap li {
  font-size: 15px;
  font-size: 1.5rem;
  margin: 5px 0 0 16px;
  list-style: url('/icon-lightbox/list-sitemap.png');
}
.srch a,
#disp.site li > a,
.cb-sitemap a {
  display: block;
  text-decoration: none;
}
.srch a:hover,
#disp.site li > a:hover,
.cb-sitemap a:hover,
.srch a:focus,
#disp.site li > a:focus,
.cb-sitemap a:focus {
  text-decoration: underline;
}
.srch .text {
  appearance: searchfield;
}
.srch th,
.srch td {
  padding-right: 0.4em;
  text-align: left;
}
.srch th:first-child {
  color: #bfbfbf;
  text-align: right;
  line-height: 2;
  font-size: 0.9em;
  font-weight: normal;
}
.srch td {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  padding-bottom: 0.8em;
}
#disp.two-step-verification {
  width: 320px;
  left: 50% !important;
  transform: translateX(-50%);
  margin-left: 0;
}
#disp.two-step-verification .two-step-verification-container {
  position: relative;
  width: 300px;
  height: 350px;
  margin-left: -15px;
  background: #fff;
}
#disp.two-step-verification .two-step-verification__div {
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  width: 100%;
  height: 100%;
}
#disp.two-step-verification .two-step-verification__a {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  height: 40px;
  min-height: 40px;
  border-radius: 0;
  background-color: #012754;
  padding: 0 20px;
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  -webkit-appearance: none;
  opacity: 0;
}
#disp.two-step-verification .loaded .two-step-verification__a {
  opacity: 1;
  transition: opacity 200ms ease-out 150ms;
}
#disp.two-step-verification .two-step-verification-container:before {
  content: "";
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 15%;
  display: block;
  width: 24px;
  height: 24px;
  margin: -12px 0 0 -12px;
  border: 3px solid rgba(113, 113, 113, 0.3);
  border-radius: 50%;
  border-top-color: #012754;
  animation: spin 1s linear infinite;
  opacity: 1;
  transition: opacity 200ms ease-in;
}
#disp.two-step-verification .two-step-verification-container.loaded:before {
  opacity: 0;
}
.ie9 #disp .two-step-verification__a {
  display: block;
  line-height: 40px;
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.flag {
  background-color: #fd0;
  color: #333;
  text-shadow: none;
}
::-moz-selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
::selection {
  background-color: #004664;
  color: #fff;
  text-shadow: none;
}
/* NEWSLETTER */
div.prog p,
div.prog img {
  float: left;
  margin-bottom: 10px;
  margin-left: 10px;
}
.grow {
  text-transform: none !important;
}
.table {
  float: left;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
}
.table-layout-fixed .table {
  table-layout: fixed;
}
.table tr {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}
.table-head tr {
  border-top: none;
}
.table th {
  font-weight: normal;
  padding: 5px 0;
}
.table--headline th {
  font-weight: bold;
}
.table td {
  padding: 5px 0;
}
.table td.align-left {
  text-align: left;
}
.table td.align-center {
  text-align: center;
}
.table td.align-right {
  text-align: right;
}
#edit .table td {
  border-left: 1px dashed #e0e0e0;
  border-right: 1px dashed #e0e0e0;
}
.table--footer .table-foot td {
  font-weight: bold;
}
@media (max-width: 979px) {
  .table {
    font-size: 1.53217569vw;
  }
}
/******* hide-non-mobile.less 2013-1-16 *******/
.body-mobile,
.cb-mobile .cb-mobile-invisible,
.cb-mobile .body-non-mobile {
  display: none;
}
.cb-mobile .body-mobile {
  display: block;
}
html,
body {
  min-height: 100%;
  height: 100%;
  background: #fff;
}
html {
  font-size: 62.5%;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(1, 39, 84, 0.4);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  width: 100%;
  background-color: transparent;
}
.desk {
  *zoom: 1;
  margin: 0 auto;
  max-width: 100%;
  width: 1572px;
  position: relative;
}
.desk:before,
.desk:after {
  display: table;
  content: '';
}
.desk:after {
  clear: both;
}
#home {
  display: block;
  height: 154px;
  margin-top: 40px;
}
.logo {
  width: auto;
  height: 100%;
}
#head {
  float: left;
  width: 100%;
  margin-top: 60px;
  position: relative;
  z-index: 2;
  text-align: center;
}
.scroll-animated {
  transition: all 1s cubic-bezier(0.05, 0.8, 0.5, 1);
  transform: translateY(100px);
  opacity: 0;
}
.scroll-animated.scroll-animated--active {
  transform: translateY(0);
  opacity: 1;
}
.wrapper {
  float: left;
  width: 100%;
  min-height: 100vh;
  color: #000;
  font-family: 'Montserrat', helvetica, sans-serif;
  line-height: 1.6;
  font-size: 15px;
  overflow: hidden;
}
.section {
  float: left;
  width: 100%;
  position: relative;
}
.section--header .desk {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.section--navigation {
  z-index: 5;
  margin-top: 24px;
  background: #fff;
}
.section--navigation:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #fff;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  pointer-events: none;
}
@media (max-width: 1199px) {
  .section--navigation {
    display: none;
  }
}
.section--navigation.cb-navi-fixed {
  z-index: 1998;
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
}
.section--navigation.cb-navi-fixed:after {
  background: #000;
}
.cb-layout1.c2 .section--side {
  position: relative;
}
.cb-layout1.c2 .section--side:before {
  content: '';
  position: absolute;
  left: 0;
  top: 120px;
  width: 100%;
  height: calc(100% - 240px);
  background-size: 100% auto;
  background-position: 50% 50%;
  background-repeat: repeat-y;
  background-image: url(/images/tapete-2022-04-07.svg);
}
.cb-layout1.c2 .section--side .desk {
  z-index: 2;
}
.section--footer {
  margin-top: 30px;
}
.navigation {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}
.navigation .service_direct-booking {
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 2;
  margin: 0;
  max-height: 0;
  padding-top: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
  overflow: hidden;
}
.navigation .service_direct-booking:after {
  border-top-width: 0;
  transition: all 0.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.cb-navi-fixed .navigation .service_direct-booking {
  max-height: 20px;
  padding-top: 12px;
  overflow: visible;
}
.cb-navi-fixed .navigation .service_direct-booking:after {
  border-top-width: 12px;
}
.navigation .service_direct-booking:hover,
.navigation .service_direct-booking:focus {
  background: #012754;
  color: #fff;
}
.navigation .service_direct-booking:hover:after,
.navigation .service_direct-booking:focus:after {
  border-top-color: #012754;
}
.moodcontent {
  float: left;
  width: 100%;
  position: relative;
}
.multimood {
  float: left;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000;
}
.square {
  position: absolute;
  top: 50%;
  width: 100%;
  height: calc(50% + 130px);
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 12px;
}
.square:before,
.square:after {
  content: '';
  position: absolute;
  top: -1px;
  width: 10px;
  height: calc(100% + 2px);
}
.square:before {
  left: -1px;
  background: url(/images/corder-white-0-0.png) left top no-repeat, url(/images/corder-white-0-100.png) left bottom no-repeat;
}
.square:after {
  right: -1px;
  background: url(/images/corder-white-100-0.png) right top no-repeat, url(/images/corder-white-100-100.png) right bottom no-repeat;
}
.booking-mask {
  float: left;
  width: 100%;
  position: relative;
  z-index: 5;
}
#sb-container {
  float: left;
  width: 100%;
  height: 130px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
#sb-container .sb {
  float: left;
  width: 100%;
}
#sb-container .sb__dates {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
#sb-container .sb-screen-xs .sb__dates .sb__form-field {
  width: calc(50% - 4px);
}
#sb-container .sb__guests {
  border: 1px solid #000;
}
.sb__form-field-input {
  border: 1px solid #012754;
}
#root #sb-container .sb__btn {
  border-radius: 0 !important;
  background: #90814e !important;
  -webkit-font-smoothing: antialiased;
}
#root #sb-container .sb__btn:hover {
  background: #012754 !important;
}
#sb-container .sb__form-field-input {
  border-radius: 0 !important;
}
.service_adults {
  position: absolute;
  right: 6%;
  bottom: 1px;
  background: #90814e;
  color: #000;
  font-family: 'LibreBaskervilleBold', 'Times New Roman', serif;
  font-size: 34px;
  line-height: 1.17647059;
  text-decoration: none;
  border: 1px solid #000;
  padding: 3px 20px;
  box-sizing: border-box;
  border-radius: 0 0 12px 0;
  -webkit-font-smoothing: antialiased;
}
.service_adults:after {
  content: '';
  position: absolute;
  right: -1px;
  bottom: -1px;
  width: 10px;
  height: 10px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/corder-white-100-100.png);
  z-index: 2;
}
.quicklinks {
  position: fixed;
  right: 0;
  top: 50%;
  width: 50px;
  transform: translateY(-50%);
  z-index: 1999;
}
.quicklinks .meta {
  float: left;
  width: 50px;
  height: 50px;
  position: relative;
  background-color: #012754;
  border: 1px solid #000;
  box-sizing: border-box;
  font-size: 0;
  line-height: 0;
  margin: 2px 0;
  background-size: 28px 28px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/quicklink-search.svg);
  border-radius: 0 0 12px 0;
}
.quicklinks .meta:after {
  content: '';
  position: absolute;
  right: -1px;
  bottom: -1px;
  width: 10px;
  height: 10px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/corder-white-100-100.png);
  z-index: 2;
}
.quicklinks .meta:first-child {
  margin-top: 0;
}
.quicklinks .meta:last-child {
  margin-bottom: 0;
}
.quicklinks .meta.service_phone {
  background-image: url(/images/quicklink-phone.svg);
}
.quicklinks .meta.service_mail {
  background-image: url(/images/quicklink-mail.svg);
}
.quicklinks .meta.service_trustyou {
  background-image: url(/images/quicklink-trustyou.png);
}
.quicklinks .meta.service_instagram {
  background-image: url(/images/quicklink-instagram.svg);
}
.quicklinks .meta.service_facebook {
  background-image: url(/images/quicklink-facebook.svg);
}
.quicklinks .meta:hover,
.quicklinks .meta:focus {
  background-color: #90814e;
}
.footsquare {
  float: left;
  width: 100%;
  border: 1px solid #000;
  border-radius: 12px;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  padding: 40px 0;
}
.footsquare:before,
.footsquare:after {
  content: '';
  position: absolute;
  top: -1px;
  width: 10px;
  height: calc(100% + 2px);
}
.footsquare:before {
  left: -1px;
  background: url(/images/corder-white-0-0.png) left top no-repeat, url(/images/corder-white-0-100.png) left bottom no-repeat;
}
.footsquare:after {
  right: -1px;
  background: url(/images/corder-white-100-0.png) right top no-repeat, url(/images/corder-white-100-100.png) right bottom no-repeat;
}
.foothome {
  float: left;
  height: 154px;
}
@media (max-width: 1199px) {
  .foothome {
    height: 144px;
  }
}
@media (max-width: 979px) {
  .foothome {
    height: 120px;
  }
}
.vcard {
  float: left;
  margin-top: 20px;
  text-align: center;
  text-transform: uppercase;
}
.vcard .org {
  font-weight: bold;
}
.vcard a {
  text-decoration: none;
  color: #000;
}
.vcard a:hover,
.vcard a:focus {
  color: #90814e;
}
.partners {
  float: left;
  width: 100%;
  margin-top: 40px;
}
.partner__title {
  float: left;
  width: 100%;
  padding-bottom: 10px;
  text-align: center;
}
.partner-width {
  float: left;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.partner {
  float: left;
  width: 292px;
  max-width: 86%;
  margin: 0 75px;
}
.partner.partner--ruedihus {
  width: 264px;
  margin-top: 25px;
}
.partner:first-child {
  margin-left: 0;
}
.partner:last-child {
  margin-right: 0;
}
.partner img {
  width: 100%;
  height: auto;
}
.service-width {
  float: left;
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
#services {
  float: left;
}
#services .meta {
  text-decoration: none;
  color: #000;
  text-transform: uppercase;
}
#services .meta:hover,
#services .meta:focus {
  color: #90814e;
}
.batches-width {
  float: left;
  width: 100%;
  margin: 20px 0 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.rating {
  float: left;
  cursor: pointer;
  display: block;
}
.rating iframe {
  float: left;
  pointer-events: none;
}
.batches {
  float: right;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
}
.batch {
  float: left;
  width: 50px;
  margin: 0 13px;
}
.batch:first-child {
  margin-left: 0;
}
.batch:last-child {
  margin-right: 0;
}
.batch.batch--2,
.batch.batch--7,
.batch.batch--8 {
  width: 88px;
}
.batch.batch--6 {
  width: 62px;
}
.direct-booking {
  position: absolute;
  left: 50px;
  top: 21.21212121%;
  z-index: 4;
  text-transform: uppercase;
  text-decoration: none;
  padding: 4px 26px;
  color: #fff;
  background: rgba(1, 39, 84, 0.9);
  -webkit-font-smoothing: antialiased;
  opacity: 0;
  transform: scale(0);
  transition: transform 0.6s 1.2s cubic-bezier(0, 1.29, 0.68, 1.2), opacity 0.6s 1.2s cubic-bezier(0, 1.29, 0.68, 1.2);
}
body.show-content .direct-booking {
  opacity: 1;
  transform: scale(1);
}
.direct-booking strong {
  text-transform: none;
  color: #90814e;
  font-family: 'LibreBaskervilleBold', 'Times New Roman', serif;
  font-size: 48px;
  line-height: 1.125;
}
.direct-booking:before,
.direct-booking:after {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
}
.direct-booking:before {
  bottom: 100%;
  border-bottom: 12px solid rgba(1, 39, 84, 0.9);
}
.direct-booking:after {
  top: 100%;
  border-top: 12px solid rgba(1, 39, 84, 0.9);
}
.direct-booking:hover,
.direct-booking:focus {
  background: rgba(144, 129, 78, 0.9);
  color: #000;
}
.direct-booking:hover strong,
.direct-booking:focus strong {
  color: #fff;
}
.direct-booking:hover:before,
.direct-booking:focus:before {
  border-bottom-color: rgba(144, 129, 78, 0.9);
}
.direct-booking:hover:after,
.direct-booking:focus:after {
  border-top-color: rgba(144, 129, 78, 0.9);
}
.maincontent {
  float: left;
  width: 100%;
}
.sidecontent {
  float: left;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;
}
.sidecontent:before,
.sidecontent:after {
  content: '';
  position: absolute;
  top: -1px;
  width: 10px;
  height: calc(100% + 2px);
}
.sidecontent:before {
  left: -1px;
  background: url(/images/corder-white-0-0.png) left top no-repeat, url(/images/corder-white-0-100.png) left bottom no-repeat;
}
.sidecontent:after {
  right: -1px;
  background: url(/images/corder-white-100-0.png) right top no-repeat, url(/images/corder-white-100-100.png) right bottom no-repeat;
}
.cb-layout1.c3 .sidecontent {
  margin-bottom: 130px;
}
@media (max-width: 979px) {
  .cb-layout1.c3 .sidecontent {
    margin-bottom: 80px;
  }
}
.cb-layout2 .sidecontent {
  background: #d8d3c2;
}
#view.side--empty .sidecontent {
  display: none;
}
#expo {
  float: left;
  width: 100%;
  position: relative;
  z-index: 2;
  height: 0;
  background: #fff;
  padding-bottom: 47.14285714%;
}
#slides {
  overflow: hidden;
  position: relative;
  max-width: 100% !important;
  max-height: 10000px !important;
  z-index: 1;
}
#slides .slide {
  width: 100%;
}
#slides img {
  display: block;
  width: 100%;
  max-width: 100% !important;
  max-height: 10000px !important;
}
#expo div.link {
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  height: 0;
  z-index: 2;
}
@media (max-width: 979px) {
  #expo div.link {
    display: none;
  }
}
#expo div.link table.link {
  display: none;
}
#expo div.link a.link {
  position: absolute;
  top: 0;
  left: 15px;
  width: 26px;
  height: 22px;
  margin-top: -11px;
  font-size: 0;
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/mood-prev.svg);
}
#expo div.link a.link.next {
  right: 15px;
  left: unset;
  background-image: url(/images/mood-next.svg);
}
#cb-cookie-warning {
  position: fixed;
  left: 40px;
  bottom: 40px;
  max-width: calc(100vw - 80px);
  width: 400px;
  z-index: 2100;
  font-family: 'Montserrat', helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.6;
  color: #000;
  background-color: #fff;
  box-shadow: 0 0 4px rgba(120, 120, 120, 0.4);
}
#cb-cookie-warning a {
  text-decoration: underline;
}
#cb-cookie-warning.cb-cookie-warning--hidden {
  display: none;
}
@media (max-width: 1199px) {
  #cb-cookie-warning {
    left: 24px;
    bottom: 24px;
    max-width: calc(100vw - 48px);
  }
}
@media (max-width: 1199px) {
  #cb-cookie-warning {
    left: 16px;
    bottom: 16px;
    max-width: calc(100vw - 32px);
  }
}
.cb-cookie-warning--container {
  float: left;
  width: 100%;
}
.cb-cookie-warning--text {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding: 30px;
}
@media (max-width: 979px) {
  .cb-cookie-warning--text {
    padding: 16px;
  }
}
.cb-cookie-warning--actions {
  float: left;
  width: 100%;
  display: flex;
}
.cb-cookie-warning__button {
  float: left;
  width: 50%;
  cursor: pointer;
}
#cb-cookie-warning__button--decline {
  text-transform: uppercase;
  letter-spacing: 0.03em;
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
}
#cb-cookie-warning__button--decline:hover {
  background-color: #f5f5f5;
}
#cb-cookie-warning__button--accept {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.03em;
  text-decoration: none;
  background: #012754;
  border: 1px solid #000;
  padding: 9px 30px;
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
  box-shadow: 0 0 0 #90814e inset;
}
#cb-cookie-warning__button--accept:hover {
  box-shadow: 300px 0 0 #90814e inset;
  transition: all 1.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
h1,
h2 {
  text-transform: uppercase;
}
h3 {
  font-size: 74px;
  line-height: 1.18918919;
  font-family: 'LibreBaskervilleBold', 'Times New Roman', serif;
  font-weight: normal;
}
.cb-layout2 .side h3 {
  text-align: center;
}
strong {
  font-weight: bold;
}
.text {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.open,
.load {
  overflow-wrap: normal;
  word-wrap: normal;
  hyphens: none;
}
.skew {
  font-family: 'LibreBaskervilleBold', 'Times New Roman', serif;
  font-size: 38px;
  line-height: 1.5;
  text-align: left;
  position: relative;
}
.skew:before,
.skew:after {
  display: inline-block;
  content: '„';
  font-size: 110px;
  line-height: 1.18181818;
  margin-right: 5px;
  margin-top: -1000px;
  margin-bottom: -1000px;
}
.skew:after {
  transform: translateY(-20px);
  margin-left: 5px;
}
a {
  color: #90814e;
  text-decoration: underline;
}
a:hover,
a:focus {
  color: #000;
}
#view div.area:empty {
  display: none;
}
.maincontent .area {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.maincontent .area div.unit {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
.maincontent .area div.unit div.part {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}
div.main {
  margin-top: -30px;
}
div.side {
  width: 100%;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  margin-top: -55px;
  margin-bottom: -55px;
}
.cb-layout1.c3 div.side {
  margin-bottom: -191px;
}
@media (max-width: 979px) {
  .cb-layout1.c3 div.side {
    margin-bottom: -101px;
  }
}
.cb-layout2 div.side {
  text-align: left;
}
div.side .unit {
  margin-top: 75px;
  margin-bottom: 75px;
}
.maincontent .area div.seam,
.maincontent .area div.flat {
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 0;
}
.maincontent .area div.seam:before,
.maincontent .area div.flat:before,
.maincontent .area div.seam:after,
.maincontent .area div.flat:after {
  content: '';
  position: absolute;
  top: -1px;
  width: 10px;
  height: calc(100% + 2px);
}
.maincontent .area div.seam:before,
.maincontent .area div.flat:before {
  left: -1px;
  background: url(/images/corder-white-0-0.png) left top no-repeat, url(/images/corder-white-0-100.png) left bottom no-repeat;
}
.maincontent .area div.seam:after,
.maincontent .area div.flat:after {
  right: -1px;
  background: url(/images/corder-white-100-0.png) right top no-repeat, url(/images/corder-white-100-100.png) right bottom no-repeat;
}
.maincontent .area div.seam div.pict,
.maincontent .area div.flat div.pict {
  margin-bottom: -71px;
}
.maincontent .area div.edge {
  box-sizing: border-box;
  border: 1px solid #000;
  border-radius: 12px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 20px 0;
}
.maincontent .area div.edge:before,
.maincontent .area div.edge:after {
  content: '';
  position: absolute;
  top: -1px;
  width: 10px;
  height: calc(100% + 2px);
}
.maincontent .area div.edge:before {
  left: -1px;
  background: url(/images/corder-white-0-0.png) left top no-repeat, url(/images/corder-white-0-100.png) left bottom no-repeat;
}
.maincontent .area div.edge:after {
  right: -1px;
  background: url(/images/corder-white-100-0.png) right top no-repeat, url(/images/corder-white-100-100.png) right bottom no-repeat;
}
div.form label.name {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.03em;
  min-height: 54px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}
div.form input.text,
div.form textarea {
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 5px 10px;
  min-height: 54px;
}
div.form div.tick.pile span.name {
  display: none;
}
div.form div.tick div.ctrl > div {
  float: left;
  width: 100%;
  position: relative;
  margin: 3px 0;
  display: flex;
  flex-wrap: nowrap;
}
div.form div.tick div.ctrl > div label {
  float: left;
  margin: 0 0 0 10px !important;
}
div.form div.tick div.ctrl > div input {
  float: left;
  margin: -1px 0 0 0 !important;
  height: 19px;
  min-height: 0;
}
div.form .recaptcha-info {
  color: rgba(255, 255, 255, 0.5);
}
div.form .recaptcha-info a {
  color: rgba(255, 255, 255, 0.5);
}
div.form input.submit {
  float: right;
  margin-top: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.03em;
  text-decoration: none;
  background: #012754;
  border: 1px solid #000;
  padding: 9px 30px;
  min-height: 44px;
  box-sizing: border-box;
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
  box-shadow: 0 0 0 #90814e inset;
}
div.form input.submit:hover {
  box-shadow: 300px 0 0 #90814e inset;
  transition: all 1.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
div.form input.submit:active {
  box-shadow: 300px 0 0 #5e5533 inset;
  transition: all 0.2s cubic-bezier(0.05, 0.8, 0.5, 1);
}
.text-section {
  float: left;
  width: 100%;
}
.list--bullet {
  padding-left: 0;
  list-style-type: none;
}
.list--bullet li {
  padding-left: 24px;
  position: relative;
}
.list--bullet li:before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
}
.list--numbered li {
  margin-left: 16px;
}
div.pict {
  box-sizing: border-box;
  border: 1px solid #000;
}
div.load {
  background: none !important;
}
div.load + div.load {
  margin-top: -15px;
}
div.load a.load {
  display: inline-block;
  text-transform: uppercase;
  color: #012754;
  text-decoration: none;
  padding-left: 24px;
  background-size: 16px 14px;
  background-position: 0 2px;
  background-repeat: no-repeat;
  background-image: url(/images/load-blue.svg);
}
div.load a.load:hover,
div.load a.load:focus {
  color: #90814e;
  background-image: url(/images/load-gold.svg);
}
div.link a.open {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.03em;
  text-decoration: none;
  background: #012754;
  border: 1px solid #000;
  padding: 9px 30px;
  border-radius: 0 0 12px 0;
  transition: all 0.8s cubic-bezier(0.05, 0.8, 0.5, 1);
  box-shadow: 0 0 0 #90814e inset;
}
div.link a.open:after {
  content: '';
  position: absolute;
  right: -1px;
  bottom: -1px;
  width: 10px;
  height: 10px;
  background-size: 100% 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/corder-white-100-100.png);
  z-index: 2;
}
div.link a.open:hover {
  box-shadow: 300px 0 0 #90814e inset;
  transition: all 1.4s cubic-bezier(0.05, 0.8, 0.5, 1);
}
/******* forms.less 2014-10-16 *******/
/* Changelog

2014-10-16
Checkboxes & Radiobuttons:
no float for inputs, no display block for labels

*/
fieldset {
  line-height: 1.6em;
}
fieldset .name,
label.sign {
  line-height: 1.2em;
  margin-top: 0px;
}
.form .name {
  padding-bottom: 0.2em;
}
input,
button,
select,
textarea {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
}
input::-ms-clear {
  /* delete input IE 10+ */
  width: 0;
  height: 0;
}
input,
textarea {
  color: #404040;
  background-color: #fff;
  font-weight: normal;
  font-family: 'Montserrat', helvetica, sans-serif;
  font-size: 15px;
  font-size: 1.5pxrem;
}
input.text,
input.sign,
input.password,
textarea,
input.hint,
textarea.hint,
input.capt {
  padding: 0.1em 3px;
  min-height: 1.6em;
  border: 1px solid #bfbfbf;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
select {
  font-size: 15px;
  font-size: 1.5pxrem;
  color: #444;
  font-weight: normal;
  font-family: 'Montserrat', helvetica, sans-serif;
  line-height: 16px;
}
textarea {
  overflow: auto;
  resize: vertical;
}
input.hint,
textarea.hint {
  color: #8c8c8c;
}
input.text[disabled],
textarea[disabled] {
  border-color: #cccccc;
  -webkit-box-shadow: none;
  box-shadow: none;
}
textarea:focus,
input.text:focus,
input.sign:focus,
input.password:focus {
  outline: none;
  border-color: #99b5de;
  box-shadow: 0 0 3px rgba(153, 181, 222, 0.7);
  transition: box-shadow 0.218s;
}
textarea::-moz-selection,
input.text::-moz-selection,
input.sign::-moz-selection,
input.password::-moz-selection {
  background-color: #c7d7ed;
}
textarea::selection,
input.text::selection,
input.sign::selection,
input.password::selection {
  background-color: #c7d7ed;
}
input.submit,
button.cb-hybrid {
  float: left;
  display: block;
  background-color: #012754;
  color: #fff;
  text-align: center;
  text-decoration: none;
  min-height: 40px;
  border-radius: 0;
  padding: 0 20px;
  -webkit-appearance: none;
}
input.submit:hover,
button.cb-hybrid:hover,
input.submit:focus,
button.cb-hybrid:focus {
  color: #fff;
  background-color: #012e63;
  text-decoration: none;
  cursor: pointer;
}
input.submit:active,
button.cb-hybrid:active {
  outline: none;
  background-color: #001022;
}
.form .foot,
.auth .foot {
  padding-bottom: 1em;
  text-align: right;
}
.form select,
.form textarea,
.form input.text,
.form input.file,
.form .name,
.form a.capt {
  display: block;
  width: 100%;
}
.form select,
.form .chop > input.file {
  float: right;
  width: 100.3%;
}
.form textarea {
  overflow: auto;
  resize: vertical;
}
.form .pile .ctrl,
.form .pile .chop {
  width: 100%;
}
.ship .chop {
  overflow: visible;
  margin-top: 0.1em;
}
.ship .chop > a {
  margin-left: 8px;
}
input.radio,
input.checkbox {
  /* add tag .unit if .unit .fade reactivated */
  margin: -3px 0.3em 0 0;
  min-height: 1.6em;
  background: none !important;
  /* IEs */
  vertical-align: middle;
}
.tick .ctrl > div,
.tick .ctrl > div {
  clear: left;
}
.form .ctrl .sign {
  min-width: 4em;
  display: inline-block;
  margin-left: 0.2em;
}
div.cb-form-sent {
  float: left;
  width: 100%;
  padding: 4px 0;
  border: solid #218823;
  border-width: 1px 0;
  background: #baeebb;
  text-align: center;
}
.cb-form-sent > .cb-form-sent {
  color: #218823;
}
input.fail,
textarea.fail {
  box-sizing: border-box;
  *behavior: url(/objects/boxsizing/boxsizing.htc);
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  padding-right: 30px !important;
  border-color: #e64246;
  color: #e64246 !important;
}
div.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  float: right;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
  border: 1px #e64246;
  border-style: solid none;
  color: #e64246;
}
div.cb-lightbox-table ul.fail {
  background: #fef6f6 url('/icons/warning.png') no-repeat 99% !important;
  color: #e64246;
}
.cb-lightbox-table textarea.fail {
  background-image: none !important;
}
.fail > p,
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin: 2px 5px;
}
.fail > p {
  font-weight: 600;
}
.fail > li,
div.cb-lightbox-table ul.fail li {
  margin-left: 1em;
  list-style: square outside;
}
.fail .sign {
  width: 90%;
}
.calendar {
  position: absolute;
  z-index: 100;
  border: 1px solid #666;
  background: #333;
  text-align: right;
  line-height: 2;
  color: #fff !important;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}
.calendar > table {
  margin: 3px;
  border-spacing: 0;
  border-collapse: collapse;
}
.calendar th {
  color: #eee;
  text-align: center;
  font-weight: 700;
}
.calendar tbody {
  border-radius: 0 0 8px 8px;
  border-top: 1px solid #666;
}
td.published {
  color: #7be;
  font-weight: 700;
}
td.show {
  background: #7be;
  color: #fff;
  font-weight: 700;
}
.calendar th > a,
.calendar td > a {
  display: block;
  padding: 0 6px 1px 6px;
  color: inherit;
  text-decoration: none;
}
.calendar th > a:hover,
.calendar td > a:hover {
  background: #666;
  color: #fff;
}
.calendar td.show {
  background: #87004e;
}
.calendar td.show a:hover {
  background: none;
}
/*** mobile-navi-multilevel-right-left.less 2014-11-03 ***/
.navi {
  display: none;
}
#mobile-navi {
  position: fixed;
  top: 0;
  width: 280px;
  height: 100%;
  z-index: 2000;
  overflow: scroll;
  background: #012754;
  transition: all 0.3s ease-in;
  -webkit-font-smoothing: antialiased;
}
.service_direct-booking {
  float: left;
  margin: 40px 0 52px 20px;
  background: #90814e;
  text-transform: uppercase;
  color: #012754;
  text-decoration: none;
  font-size: 14px;
  letter-spacing: 0.03em;
  line-height: 1.14285714;
  padding: 12px 30px 0;
  position: relative;
}
.service_direct-booking:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 0;
  border-top: 12px solid #90814e;
  border-right: 12px solid transparent;
  box-sizing: border-box;
}
.service_direct-booking:hover,
.service_direct-booking:focus {
  color: #012754;
}
.mobile-links {
  float: left;
  width: 100%;
  box-sizing: border-box;
  padding-left: 20px;
  margin-bottom: 30px;
}
.mobile-links a {
  font-size: 16px;
  line-height: 1.25;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 0.03em;
}
.togglenavigation {
  position: fixed;
  top: 20px;
  display: block;
  width: 28px;
  height: 20px;
  z-index: 2001;
  cursor: pointer;
  color: #012754 !important;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.5s cubic-bezier(0.89, -0.4, 0.53, 1.56);
  background: #90814e;
  border: 1px solid #000;
  padding: 5px;
}
.togglenavigation:active > span {
  background: #01142c !important;
}
.togglenavigation > span {
  left: 5px;
  top: 50%;
  position: absolute;
  display: block;
  height: 4px;
  width: 28px;
  margin-top: -2px;
  background: currentColor;
}
.togglenavigation > .tline-1,
.togglenavigation > .tline-4 {
  top: 5px;
  margin-top: 0;
}
.togglenavigation > .tline-4 {
  top: auto;
  bottom: 5px;
}
.cb-toggle-target-active .togglenavigation > .tline-1,
.cb-toggle-target-active .togglenavigation > .tline-4 {
  opacity: 0;
}
.cb-toggle-target-active .togglenavigation > .tline-2,
.cb-toggle-target-active .togglenavigation > .tline-3 {
  transition: transform 0.218s cubic-bezier(0.64, -0.39, 0.67, 1.5);
}
.cb-toggle-target-active .togglenavigation > .tline-2 {
  transform: rotate(-45deg);
}
.cb-toggle-target-active .togglenavigation > .tline-3 {
  opacity: 1;
  transform: rotate(45deg);
}
body.cb-toggle-target-active #mobile-navi {
  transition: all 0.218s ease-out;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
body.cb-toggle-target-active .togglenavigation {
  transition: all 0.4s cubic-bezier(0.21, 0.53, 0.44, 1.24);
}
/* RIGHT */
#mobile-navi {
  right: -280px;
}
.togglenavigation {
  right: 6%;
}
body.cb-toggle-target-active #mobile-navi {
  right: 0;
}
/* LEFT */
#mobile-navi .navi {
  float: left;
  display: block;
  width: 100%;
}
#mobile-navi .navi .item {
  width: 100%;
  float: left;
  position: relative;
}
#mobile-navi .navi .item.path {
  background: rgba(0, 0, 0, 0.9);
}
#mobile-navi .navi .item .menu {
  display: block;
  color: #fff;
  padding: 5px 20px;
  padding-right: 54px;
  font-size: 16px;
  line-height: 1.5;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}
#mobile-navi .navi .item .menu.path {
  color: #90814e;
}
#mobile-navi .navi.sub1 {
  margin: 5px 0;
}
#mobile-navi .navi.sub1 .item {
  width: auto;
}
#mobile-navi .navi.sub1 .item.path {
  background: none;
}
#mobile-navi .navi.sub1 .item .menu {
  font-size: 16px;
  line-height: 1.25;
  padding: 0;
}
#mobile-navi .navi.sub1 .item .menu.path {
  color: #90814e;
}
#mobile-navi .navi.sub2 {
  margin-top: 90px;
}
#mobile-navi .navi.sub3 {
  max-height: 0;
  overflow: hidden;
}
#mobile-navi .navi.sub3 .item .menu:before {
  content: '- ';
}
#mobile-navi div.navi > .item.cb-toggle-target-active > div.navi {
  max-height: 1000px;
  transition: all 2s;
}
#mobile-navi .cb-toggle {
  display: block;
  position: absolute;
  right: 20px;
  top: 0;
  z-index: 3;
  width: 34px;
  height: 34px;
  background-size: 14px 14px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/mnavi.svg);
}
#mobile-navi .cb-toggle:hover,
#mobile-navi .cb-toggle:focus {
  cursor: pointer;
}
#mobile-navi .cb-toggle.cb-toggle-active {
  transform: rotate(-180deg);
  transition: all 0.218s;
}
#mobile-navi div.sub3 .cb-toggle {
  height: 34px;
}
#mobile-navi .navi > .cb-toggle,
#mobile-navi .navi .item-empty > .cb-toggle {
  display: none;
}
.desk {
  max-width: 94%;
}
#home {
  height: 120px;
  margin-top: 20px;
  margin-bottom: 34px;
}
#head {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 30px;
}
.service_adults {
  font-size: 14px;
  line-height: 1.28571429;
  padding: 2px 10px;
  transition: right 0.6s 1s cubic-bezier(0, 1.29, 0.68, 1.2), transform 0.6s 1s cubic-bezier(0, 1.29, 0.68, 1.2);
  transform: translateX(100%);
  right: 0;
}
body.show-content .service_adults {
  transform: translateX(0);
  right: 6%;
}
.navigation,
.multimood,
.booking-mask {
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}
.square {
  height: calc(50% + 10px);
}
.quicklinks {
  width: 30px;
}
.quicklinks .meta {
  width: 30px;
  height: 30px;
  background-size: 16px 16px;
}
@media (max-width: 979px) {
  .square {
    height: calc(50% + 110px);
  }
  #sb-container {
    height: 110px;
  }
}
@media (max-width: 815px) {
  .square {
    height: calc(50% + 250px);
  }
  #sb-container {
    height: 250px;
  }
}
@media (max-width: 362px) {
  .square {
    height: calc(50% + 350px);
  }
  #sb-container {
    height: 350px;
  }
}
@media (max-width: 979px) {
  .direct-booking {
    top: 22px;
    left: 10px;
    font-size: 6px;
    padding: 0 10px;
  }
  .direct-booking strong {
    font-size: 18px;
  }
}
@media (max-width: 979px) {
  .vcard .sep,
  .vcard br {
    display: none;
  }
  .vcard .org,
  .vcard .adr,
  .vcard .street-address,
  .vcard .tel {
    display: block;
  }
  .partner-width {
    flex-direction: column;
    align-items: center;
  }
  .partner {
    margin: 0;
  }
  #services {
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
    text-align: center;
  }
  div.sub1 {
    float: left;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 94%;
    margin-left: 3%;
    margin-right: 3%;
  }
  div.sub1 > .item {
    float: left;
  }
  div.sub1 > .item.init > .menu {
    margin-left: 0;
  }
  div.sub1 > .item.init > .menu:before {
    display: none;
  }
  div.sub1 > .item > .menu {
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
    margin-left: 4px;
  }
  div.sub1 > .item > .menu:before {
    content: '| ';
  }
  div.sub1 > .item > .menu:hover,
  div.sub1 > .item > .menu:focus {
    color: #90814e;
  }
  .batches-width {
    float: left;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }
  .rating {
    max-width: 94%;
    margin-bottom: 50px;
  }
  .batches {
    justify-content: space-between;
    width: 94%;
    max-width: 382px;
  }
  .batch {
    margin: 0;
    max-width: 22%;
  }
}
@media (max-width: 979px) {
  h3 {
    font-size: 35px;
    line-height: 1.2;
  }
  .skew {
    font-size: 24px;
    line-height: 1.25;
  }
  .skew:before,
  .skew:after {
    font-size: 50px;
    line-height: 1.2;
  }
  .skew:before {
    margin-right: 0;
  }
  .skew:after {
    transform: translateY(-10px);
  }
}
.side {
  width: 100%;
}
.side > .unit {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.side .foot {
  margin-right: 3%;
  margin-left: 3%;
}
.side .part {
  margin-right: 3%;
  margin-left: 3%;
  width: 94%;
}
.side > .slim .part {
  width: 94%;
}
@media (max-width: 979px) {
  div.side {
    margin-top: 0;
    margin-bottom: 0;
  }
  div.side .unit {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
.maincontent .area div.pure {
  width: 94%;
}
.maincontent .area div.seam,
.maincontent .area div.flat {
  margin-bottom: 80px;
  padding-right: 3%;
  padding-left: 3%;
}
.maincontent .area div.edge {
  padding-right: 3%;
  padding-left: 3%;
}
#disp {
  left: 3%;
  margin-left: 0;
  width: 94%;
  font-size: 13px;
  font-size: 1.3rem;
  top: 90px !important;
}
#disp.site li,
div.cb-sitemap li {
  font-size: 14px;
  font-size: 1.4rem;
}
/*# sourceMappingURL=./screen-small.css.map */